import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFView,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  about2bg,
  aboutParallax,
  eatingAward,
  mobileAbout,
  mobileAboutParallax,
  rated,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="About" />
          <CFLink
            href="https://www.thebestvancouver.com/best-japanese-restaurant-vancouver/"
            target="_blank"
          >
            <CFView column center mt="-15px" mb="20px">
              <CFImage w="80%" src={rated} alt="About" />
            </CFView>
          </CFLink>
        </CFView>
        <Parallax
          bgImage={mobileAboutParallax}
          bgImageAlt="Entree Dish"
          strength={200}
        >
          <div style={{ height: '300px' }} />
        </Parallax>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%">
          <CFImage src={about} w="100%" maxWidth="1400px" alt="About" />
          <CFView
            w="100%"
            image={`url(${about2bg}) center / cover no-repeat`}
            zIndex={90}
            row
            center
            pb="35px"
          >
            <CFLink
              href="https://www.thebestvancouver.com/best-japanese-restaurant-vancouver/"
              target="_blank"
            >
              <CFView w="100%" column center>
                <CFImage h="75px" src={rated} alt="About" />
              </CFView>
            </CFLink>
            <CFLink
              href="https://www.eatingvancouver.ca"
              target="_blank"
              title="Eating Vancouver Top Restaurant Award"
            >
              <CFView w="100%" column center ml="35px">
                <CFImage
                  h="75px"
                  src={eatingAward}
                  alt="Eating Vancouver Top Restaurant Award"
                />
              </CFView>
            </CFLink>
          </CFView>
        </CFView>
        <CFView
          row
          justifyStart
          alignCenter
          pv="10%"
          pr="40px"
          h="600px"
          maxWidth="1400px"
          image={`url(${aboutParallax}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
